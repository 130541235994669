import React from 'react';

import OurEngagementModel from './OurEngagementModel';
import BeyondCoding from './BeyondCoding';
import SetOfSkills from './SetOfSkills';
import WelcomeToOurHome from './WelcomeToOurHome';
import JumpToTop from '../../jumpToTop';

const EngagementModel = (props) => {
  return (
    <div className="how-we-work">
      <OurEngagementModel data={props.data.headingSection} />
      <BeyondCoding data={props.data} />
      <SetOfSkills data={props.data.setOfSkills} />
      <WelcomeToOurHome data={props.data.engagementHeading} />
      <JumpToTop data={props.globalData} />
    </div>
  );
};

export default EngagementModel;
