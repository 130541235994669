import React from 'react';
import PropTypes from 'prop-types';

import JobPosition from './JobPositions';
// import JobProcess from './JobProcess';
import JobForYou from './JobForYou';
import useJobsData from '../../../queries/JobsQueries';
import JumpToTop from '../../jumpToTop';

const Jobs = (props) => {
  const jobs = useJobsData();
  return (
    <>
      <div className="jobs">
        {/* <JobProcess data={props.data} /> */}
        <div className="container job-positions-wrapper">
          <JobForYou data={props.data} />
          <div className="row">
            {jobs.map((job) => (
              <JobPosition
                className="job"
                slug={job.slug}
                key={job.id}
                subtitle={job.subtitle}
                positonTitle={job.title}
                text={job.text}
              />
            ))}
          </div>
        </div>
      </div>
      <JumpToTop data={props.globalData} />
    </>
  );
};

Jobs.propTypes = {
  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Jobs;
