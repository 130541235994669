import { graphql, useStaticQuery } from 'gatsby';

const useJobsData = () => {
  const {
    allStrapiJobs: { nodes: jobs },
  } = useStaticQuery(graphql`
    {
      allStrapiJobs {
        nodes {
          slug
          id
          title
          subtitle
          text
        }
      }
    }
  `);

  return jobs;
};

export default useJobsData;
