import { graphql, useStaticQuery } from 'gatsby';

const useTestimonialsData = () => {
  const {
    allStrapiTestimonials: { nodes: testimonials },
  } = useStaticQuery(graphql`
    {
      allStrapiTestimonials {
        nodes {
          title
          description
          name
          position
          image {
            url
          }
        }
      }
    }
  `);

  return testimonials;
};

export default useTestimonialsData;
