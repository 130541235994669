import { graphql, useStaticQuery } from 'gatsby';

const useProjectsData = () => {
  const {
    allStrapiProjects: { nodes: projects },
  } = useStaticQuery(graphql`
    {
      allStrapiProjects {
        nodes {
          slug
          description
          nextProject
          previousProject
          title
          highlightProject
          icon {
            url
          }
          image {
            url
          }
          darkIcon {
            url
          }
          projectContainer {
            ProjectDetails {
              industry
              industryTitle
              scopeOfWork
              scopeOfWorkTitle
              teamSize
              teamSizeTitle
              technologies
              technologiesTitle
            }
            title
            content
            changeableContent
            image {
              url
            }
          }
        }
      }
    }
  `);

  return projects;
};

export default useProjectsData;
