/* eslint-disable react/no-danger */
import React from 'react';
import Tilt from 'react-tilt';
import PropTypes from 'prop-types';

const pic = 'https://imagizer.imageshack.com/img923/6328/PVrE7Z.png';
const leaf = 'https://imagizer.imageshack.com/img923/7836/42rVgn.png';

const WelcomeToOurHome = (props) => (
  <div className="welcome-to-our-home">
    <div className="container">
      <h1>{props.data.title}</h1>
      <p className="p-header">{props.data.subtitle}</p>
    </div>

    <div className="leaf-wrapper">
      <div className="box bounce">
        <Tilt className="Tilt" options={{ max: 45, scale: 1.2 }}>
          <div className="Tilt-inner">
            <img src={leaf} alt="leaf bounce" />
          </div>
        </Tilt>
      </div>
      <div className="container image-container">
        <img src={pic} alt="antcolony company light coding" />
      </div>
    </div>
    <div className="container">
      <div
        dangerouslySetInnerHTML={{
          __html: props.data?.secondaryText,
        }}
      />
    </div>
  </div>
);

WelcomeToOurHome.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
  }).isRequired,
};

export default WelcomeToOurHome;
