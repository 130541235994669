import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const pic = 'https://imagizer.imageshack.com/img923/715/Fu3eBQ.png';

const BeyondCoding = (props) => (
  <div className="beyond-coding">
    <div className="container">
      <h1>{props.data.title}</h1>
      <div className="container px-0">
        <div className="one-row">
          <div className="col-12 col-md-6 beyond-coding--column">
            <h2 className="beyond-coding--number pb-3 pt-md-3">
              {props.data.ctaStep.number}
            </h2>
            <h2>{props.data.ctaStep.title}</h2>
            <p className="pr-md-5">{props.data.ctaStep.description}</p>
            <Link to={props.data.ctaButton.link}>
              <button type="button" className="btn beyond-coding--button">
                {props.data.ctaButton.text}
              </button>
            </Link>
          </div>
          <div className="col-12 col-md-6 beyond-coding--column ">
            <img src={pic} alt="antcolony client managed model" />
          </div>
          <div className="client-model-row pt-3">
            {props.data.steps.map((item) => (
              <div
                key={item.number}
                className="col-12 col-md-6 beyond-coding--column"
              >
                <h2 className="beyond-coding--number pb-3 ">{item.number}</h2>
                <h2>{item.title}</h2>
                <p className="pr-md-5">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

BeyondCoding.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    ctaStep: PropTypes.shape({
      number: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    ctaButton: PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default BeyondCoding;
