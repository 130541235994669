import React from 'react';
import PropTypes from 'prop-types';

const Workflow = (props) => (
  <div className="workflow">
    <div className="container">
      <h1>{props.data.title}</h1>
      <p className="p-text">{props.data.subtitle}</p>
      <div className="container px-0">
        <div className="one-row ">
          {props.data.steps.map((step) => (
            <div key={step.number} className="col-12 col-md-6 workflow-column">
              <img src={step.picture.url} alt="pic" />
              {step.title && <h2>{step.title}</h2>}
              <div className="d-flex workflow-row">
                {step.heading && (
                  <div className="d-flex">
                    <div className="Oval">{step.number}</div>
                    <h5>{step.heading}</h5>
                  </div>
                )}
                <p className="p-workflow">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

Workflow.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        picture: PropTypes.shape({
          url: PropTypes.string,
        }),
        title: PropTypes.string,
        number: PropTypes.number,
        heading: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default Workflow;
