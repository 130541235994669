/* eslint-disable react/no-danger */
import React from 'react';
import Tilt from 'react-tilt';
import PropTypes from 'prop-types';

const pic = 'https://imagizer.imageshack.com/img922/3426/mT3hO4.png';

const OurTradition = ({ content }) => (
  <div className="our-tradition">
    <div className="container">
      <h1>{content.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: content?.subtitle }} />
      <div className="container">
        <Tilt className="Tilt" options={{ max: 35 }}>
          <div className="Tilt-inner">
            <img src={pic} alt="antcolony tradition ant" />
          </div>
        </Tilt>
      </div>
    </div>
  </div>
);

OurTradition.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default OurTradition;
