/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import useTestimonialsData from '../../../../queries/TestimonialsQueries';

import SliderCard from './SliderCard';
import NextArrow from './SliderArrow/NextArrow';
import PrevArrow from './SliderArrow/PrevArrow';

import './_testimonials.scss';

const Testimonials = (props) => {
  const testimonials = useTestimonialsData();
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="testimonials-container">
      <h1 className="testimonials-title">{props.data.title}</h1>
      <Slider {...settings}>
        {testimonials.map((item) => (
          <SliderCard
            key={item.name}
            name={item.name}
            image={item.image.url}
            position={item.position}
            description={item.description}
          />
        ))}
      </Slider>
    </div>
  );
};

Testimonials.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Testimonials;
