import React from 'react';
import Fade from 'react-reveal/Fade';

const GalleryItem = (props) => (
    <Fade>
        <div className="col-6 col-md-4 px-1 py-1 gallery-item-container ">
            <img src={props.img} alt="gallery antcolony" />
        </div>
    </Fade>

)

export default GalleryItem;