/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './_projectCard.scss';

const ProjectCard = (props) => (
  <Link to={`/projects/${props.slug}`} className="project-card">
    <div className="project-image-wrapper">
      <div className="project-card-wrapper">
        <img src={props.image} alt={props.title} className="project-image" />
        <div className="icon-wrapper">
          <img src={props.icon} alt={props.title} className="project-icon" />
        </div>
      </div>
    </div>
    <div className="project-title-wrapper">
      <div className="project-title">
        {props.title} -{' '}
        <span className="project-description">{props.description}</span>
      </div>
    </div>
  </Link>
);

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default ProjectCard;
