import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const pic1 = 'https://imagizer.imageshack.com/img922/8643/WhlwtM.png';
const rocket = 'https://imagizer.imageshack.com/img921/1073/px9XBK.png';

const StartupIdea = (props) => (
  <div className="startup-idea">
    <div className="container">
      <h1>{props.data.title}</h1>
      <p className="p-header">{props.data.subtitle}</p>
      <Link to={props.data.CTA.link}>
        <button type="button" className="startup-idea-button">
          {props.data.CTA.text}
        </button>
      </Link>
      <div className="rocket-wrapper">
        <div className="box bounce">
          <img src={rocket} alt="rocket bounce" />
        </div>
        <div className="image-container">
          <img src={pic1} alt="pic" />
        </div>
      </div>
    </div>
  </div>
);

StartupIdea.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    CTA: PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default StartupIdea;
