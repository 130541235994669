/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import ProjectCard from '../Home/Projects/ProjectCard';
import useProjectsData from '../../../queries/ProjectsQueries';

const desktopClients =
  'https://ant-strapi-storage-bucket.fra1.digitaloceanspaces.com/antcolony-website/589550ab185f1d4d8a7e225598e380d4.png';

const mobileClients =
  'https://ant-strapi-storage-bucket.fra1.digitaloceanspaces.com/antcolony-website/9fefd3b1f0e92f85648fab0467b521e5.png';

const Projects = (props) => {
  const projects = useProjectsData();
  const highlightedProjects = projects
    .filter((item) => item.highlightProject === true)
    .sort()
    .reverse();
  const projectList = projects.filter((item) => item.highlightProject === null);
  return (
    <div className="projects">
      <div className="container projects-header">
        <h1 className="our-other-clients-header">
          {props.data.heading.subtitle}
        </h1>
      </div>
      <div className="container our-other-clients py-5">
        <img
          src={desktopClients}
          id="otherClientsMobile"
          alt="Our other clients"
          className="d-none d-md-block"
        />
        <img
          src={mobileClients}
          id="otherClientsMobile"
          alt="Our other clients"
          className="d-md-none"
        />
      </div>
      <div className="container projects-header">
        <h1>{props.data.secondaryHeading.title}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: props.data.secondaryHeading?.subtitle,
          }}
          className="projects-subtitle"
        />
      </div>
      <div className="highlighted-project-container">
        <Link
          to={`/projects/${highlightedProjects[0].slug}`}
          className="highlighted-project-wrapper container"
        >
          <img
            src={highlightedProjects[0].image.url}
            alt={highlightedProjects[0].title}
          />
          <div>
            <div className="detail-project-title">
              {highlightedProjects[0].title} -
              <span className="project-description">
                {' '}
                {highlightedProjects[0].description}
              </span>
            </div>
          </div>
        </Link>
      </div>
      <div className="other-projects-wrapper container">
        {projectList.slice(0, 6).map((item) => (
          <div className="other-projects-card">
            <ProjectCard
              title={item.title}
              image={item.image.url}
              icon={item.icon.url}
              description={item.description}
              slug={item.slug}
            />
          </div>
        ))}
      </div>
      <div className="highlighted-project-container">
        <Link
          to={`/projects/${highlightedProjects[1].slug}`}
          className="highlighted-project-wrapper container"
        >
          <img
            src={highlightedProjects[1].image.url}
            alt={highlightedProjects[1].title}
          />
          <div>
            <div className="detail-project-title">
              {highlightedProjects[1].title} -
              <span className="project-description">
                {' '}
                {highlightedProjects[1].description}
              </span>
            </div>
          </div>
        </Link>
      </div>
      <div className="other-projects-wrapper container">
        {projectList.slice(6, 12).map((item) => (
          <div className="other-projects-card">
            <ProjectCard
              title={item.title}
              image={item.image.url}
              icon={item.icon.url}
              description={item.description}
              slug={item.slug}
            />
          </div>
        ))}
      </div>
      <div className="highlighted-project-container">
        <Link
          to={`/projects/${highlightedProjects[2].slug}`}
          className="highlighted-project-wrapper container"
        >
          <img
            src={highlightedProjects[2].image.url}
            alt={highlightedProjects[2].title}
          />
          <div>
            <div className="detail-project-title">
              {highlightedProjects[2].title} -
              <span className="project-description">
                {' '}
                {highlightedProjects[2].description}
              </span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

Projects.propTypes = {
  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    heading: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string.isRequired,
    }).isRequired,
    secondaryHeading: PropTypes.shape({
      subtitle: PropTypes.string.isRequired,
      studiesHeading: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        firstProjectTitle: PropTypes.string,
        firstProjectSubtitle: PropTypes.string,
        firstProjectDescription: PropTypes.string,
        secondProjectMainText: PropTypes.string,
        secondProjectTitle: PropTypes.string,
        secondProjectSubtitle: PropTypes.string,
        secondProjectDescription: PropTypes.string,
        firstProjectImage: PropTypes.shape({
          url: PropTypes.string,
        }),
        secondProjectImage: PropTypes.shape({
          url: PropTypes.string,
        }),
        secondProjectTextOrientation: PropTypes.string,
      }).isRequired,
    ).isRequired,
    caseStudies: PropTypes.arrayOf(
      PropTypes.shape({
        firstProjectTitle: PropTypes.string,
        firstProjectSubtitle: PropTypes.string,
        firstProjectDescription: PropTypes.string,
        secondProjectMainText: PropTypes.string,
        secondProjectTitle: PropTypes.string,
        secondProjectSubtitle: PropTypes.string,
        secondProjectDescription: PropTypes.string,
        firstProjectImage: PropTypes.shape({
          url: PropTypes.string,
        }),
        secondProjectImage: PropTypes.shape({
          url: PropTypes.string,
        }),
        secondProjectTextOrientation: PropTypes.string,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default Projects;
