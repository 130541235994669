/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { Icon } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';

import JumpToTop from '../../jumpToTop';

const Contact = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    text: '',
  });

  const [buttonLoading, setButtonLoading] = useState('notClicked');

  const { name, email, phone, subject, text } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    setButtonLoading('loading');

    const data = {
      name: `New request from: ${name} | ${subject}`,
      email: `${email}`,
      // eslint-disable-next-line
      message: `User with following information \n\ e-mail: ${email} \n\ phone: ${phone} \n\ has sent following message: \n\ ${text}`,
    };

    axios
      .post('https://ant-colony-mailing.herokuapp.com/submit', data)
      .then(function (response) {
        setButtonLoading('received');
      })
      .catch(function (error) {
        setButtonLoading('received');
      });
  };

  const getButtonStateClassName = () => {
    if (buttonLoading === 'loading') {
      return 'active';
    }
    if (buttonLoading === 'received') {
      return 'finished';
    }
    return '';
  };

  return (
    <div className="contact">
      <div className="container">
        <div className="contact-header px-4 pb-5">
          <h1 className="mb-2">{props.data.contactHeader}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: props.data?.contactParagraph,
            }}
          />
        </div>
        <form
          className="form col-12 col-md-7 mx-md-auto"
          onSubmit={(e) => onSubmit(e)}
        >
          <div className="form-group">
            <div className=" one-row">
              <h6 className="col-12 col-md-2 px-0 px-md-3 pb-2 pb-md-0">
                {props.data.name}
              </h6>
              <input
                className="input-area col-12 col-md-9"
                type="name"
                placeholder={props.data.placeholderName}
                name="name"
                value={name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className=" one-row">
              <h6 className="col-12 col-md-2 px-0 px-md-3 pb-2 pb-md-0">
                {props.data.email}
              </h6>
              <input
                className="input-area col-12 col-md-9"
                type="email"
                placeholder={props.data.placeholderEmail}
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className=" one-row">
              <h6 className="col-12 col-md-2 px-0 px-md-3 pb-2 pb-md-0">
                {props.data.phone}
              </h6>
              <input
                className="input-area col-12 col-md-9"
                type="text"
                placeholder={props.data.placeholderPhone}
                name="phone"
                value={phone}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="form-group">
            <div className=" one-row">
              <h6 className="col-12 col-md-2 px-0 px-md-3 pb-2 pb-md-0">
                {props.data.subject}
              </h6>
              <input
                className="input-area col-12 col-md-9"
                type="text"
                placeholder={props.data.placeholderSubject}
                name="subject"
                value={subject}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="">
              <textarea
                className="col-12"
                type="text"
                placeholder={props.data.placeholderText}
                name="text"
                value={text}
                onChange={(e) => onChange(e)}
                rows="7"
                required
              />
            </div>
          </div>
          <div className="one-row">
            <h6 className="px-0 px-md-3 pb-2 pb-md-0">{props.data.text}</h6>
            <button
              type="submit"
              className={`send-button ${getButtonStateClassName()}`}
              value="Send"
            >
              <span className="submit">{props.data.buttonText}</span>
              <span className="loading">
                <Icon
                  type="sync"
                  spin
                  style={{ fontSize: '28px', color: 'red' }}
                />
              </span>
              <span className="check">
                <Icon
                  type="check-circle"
                  style={{ fontSize: '30px', color: 'red' }}
                />
              </span>
            </button>
          </div>
        </form>
      </div>
      <JumpToTop data={props.globalData} />
    </div>
  );
};

Contact.propTypes = {
  data: PropTypes.shape({
    contactHeader: PropTypes.string.isRequired,
    contactParagraph: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholderName: PropTypes.string.isRequired,
    placeholderEmail: PropTypes.string.isRequired,
    placeholderPhone: PropTypes.string.isRequired,
    placeholderSubject: PropTypes.string.isRequired,
    placeholderText: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  }).isRequired,

  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Contact;
