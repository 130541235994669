import React from 'react';
import PropTypes from 'prop-types';

const pic = 'https://imagizer.imageshack.com/img921/6987/ex1nMl.png';

const JobForYou = (props) => (
  <div className="job-for-you">
    <div className="container">
      <h1>{props.data.title}</h1>
      <img src={pic} alt="antcolony we have job for you" />
    </div>
  </div>
);

JobForYou.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default JobForYou;
