import React from 'react';
import { Link } from 'gatsby';
import Tilt from 'react-tilt';
import PropTypes from 'prop-types';

const pic1 = 'https://imagizer.imageshack.com/img922/1835/rAq17Z.png';

const Fun = (props) => (
  <div className=" fun">
    <div className="container">
      <h1>{props.data.title}</h1>
      <p>{props.data.subtitle}</p>
      <Link to={props.data.CTA.link}>
        <button type="button" className="fun-button">
          {props.data.CTA.text}
        </button>
      </Link>
      <div className="row">
        <div className="col-12 col-md-6">
          <Tilt className="Tilt" options={{ max: 35, scale: 1 }}>
            <div className="Tilt-inner">
              <img src={pic1} alt="Snowboarding" />
            </div>
          </Tilt>
        </div>
        <div className="col-12 col-md-6 fun-row">
          <h2>{props.data.secondaryTitle}</h2>
          <p className="pr-md-5">{props.data.secondarySubtitle}</p>
        </div>
      </div>
    </div>
  </div>
);

Fun.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    CTA: PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    secondarySubtitle: PropTypes.string.isRequired,
    secondaryTitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default Fun;
