import React from 'react';
import PropTypes from 'prop-types';

const JumpToTop = (props) => {
  const img = 'https://imagizer.imageshack.com/img922/7472/Z0KYeh.png';
  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div className="container jump-to-top">
      <div
        onClick={scrollToTop}
        aria-hidden="true"
        className="jump-to-top--container"
      >
        <input type="image" src={img} alt="jump to top " />
        <h5>{props.data.jumpToTop.title}</h5>
      </div>
    </div>
  );
};

JumpToTop.propTypes = {
  data: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default JumpToTop;
