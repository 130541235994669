import React from 'react';
import PropTypes from 'prop-types';

const pic = 'https://imagizer.imageshack.com/img921/3814/FwZ9j8.png';

const SetOfSkills = (props) => (
  <div className="set-of-skills">
    <div className="container">
      <h1>{props.data.title}</h1>
      <img src={pic} alt="antcolony skills technologies agile" />
    </div>
  </div>
);

SetOfSkills.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default SetOfSkills;
