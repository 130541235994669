import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import './sliderArrow.scss';

const NextArrow = (props) => (
  <div
    onClick={props.onClick}
    role="presentation"
    className="next-slider-arrow"
  >
    <Icon
      type="arrow-right"
      style={{ color: 'black', verticalAlign: 0, fontSize: 20 }}
    />
  </div>
);

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  onClick: () => {},
};

export default NextArrow;
