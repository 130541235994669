import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import { Icon } from 'antd';

import useProjectsData from '../../../../queries/ProjectsQueries';
import ProjectCard from './ProjectCard';

import './_projects.scss';

const HomeProjects = (props) => {
  const projects = useProjectsData();
  return (
    <div className="our-aim">
      <div className="container">
        <div className="d-flex our-aim-contact">
          <p className="our-aim-contact--text pl-0">
            {props.data.contactUsText}
          </p>
          <Link to="/contact">
            <button type="button" className="our-aim-contact--button">
              <Icon type="arrow-right" />
            </button>
          </Link>
        </div>
        <h1>{props.data.title}</h1>
        <p className="p-header">{props.data.description}</p>
        <div className="projects-wrapper">
          {projects.slice(0, 4).map((item) => (
            <div className="project-card">
              <ProjectCard
                title={item.title}
                image={item.image.url}
                icon={item.icon.url}
                description={item.description}
                slug={item.slug}
              />
            </div>
          ))}
        </div>
      </div>
      <Link to="projects" className="button-container">
        <button type="button" className="startup-idea-button">
          VIEW ALL PROJECTS
        </button>
      </Link>
    </div>
  );
};

HomeProjects.propTypes = {
  data: PropTypes.shape({
    contactUsText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default HomeProjects;
