import React from 'react';
import PropTypes from 'prop-types';

import GalleryItem from './Items/index';
import JumpToTop from '../../jumpToTop';

const Gallery = (props) => (
  <div className="gallery">
    <div className="container">
      <h1>{props.data.title}</h1>
      <div className="items-row">
        {props.data.images.map((pic) => (
          <GalleryItem key={pic.image} img={pic.image.url} />
        ))}
      </div>
    </div>
    <JumpToTop data={props.globalData} />
  </div>
);

Gallery.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Gallery;
