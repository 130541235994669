import { useStaticQuery, graphql } from 'gatsby';

const useMeetTheTeamData = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiAnts(sort: { fields: order_id }) {
        nodes {
          antPortrait {
            url
          }
          id
          about
          name
          antImage {
            url
          }
          text
        }
      }
    }
  `);

  return { ants: data.allStrapiAnts.nodes };
};

export default useMeetTheTeamData;
