import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Ant from './Ant';
import OurTradition from './OurTradition/OurTradition';
import useWindowSize from '../../../utils/useWindowSize';
import JumpToTop from '../../jumpToTop';
import meetTheTeamData from '../../../queries/MeetTheTeamQueries';

const Team = (props) => {
  const size = useWindowSize();

  const { ants } = meetTheTeamData();

  const [antImgHeight, setAntImgHeight] = useState('');

  const antImg = useRef(null);

  useEffect(() => {
    if (antImg.current) {
      setAntImgHeight(`${antImg.current.getBoundingClientRect().width}px`);
    }
  }, [size]);

  return (
    <div className="team">
      <OurTradition content={props.data.heading} />
      <div className="container ants">
        <h1>{props.data.meetTheMembers}</h1>
        <div className="ants-row">
          {ants.map((ant) => (
            <Ant
              height={antImgHeight}
              key={ant.name}
              about={ant.about}
              name={ant.name}
              ant={ant.antImage.url}
              text={ant.text}
              portrait={ant.antPortrait.url}
            />
          ))}
          <div className="hidden-ant col-12 col-sm-4 ">
            <div
              className="ant-img-container"
              ref={antImg}
              style={{ height: `${antImgHeight}`, width: '50%' }}
            />
          </div>
        </div>
      </div>
      <JumpToTop data={props.globalData} />
    </div>
  );
};

Team.propTypes = {
  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    heading: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }).isRequired,

    meetTheMembers: PropTypes.string.isRequired,
  }).isRequired,
};

export default Team;
