import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

const OurAim = (props) => (
  <div className="our-aim">
    <div className="container our-aim-container--picture">
      <Fade left>
        <div className="our-aim-container--first-picture mr-md-2" />
      </Fade>
      <Fade right>
        <div className="our-aim-container--second-picture ml-md-2" />
      </Fade>
    </div>

    <div className="container our-aim-card">
      <div className="row">
        <div className="our-aim-container--first-picture" id="first-picture" />
        <div
          className="our-aim-container--second-picture"
          id="second-picture"
        />
        {props.data.sectionHeading.steps.map((step) => (
          <div
            key={step.number}
            className="col-12 col-md-6 our-aim-card-row"
            id="dedication"
          >
            <div className="d-flex aim-header">
              <div className="Oval">{step.number}</div>
              <h5>{step.heading}</h5>
            </div>
            <h2>{step.title}</h2>
            <p className="col-md-10 pl-0 ">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

OurAim.propTypes = {
  data: PropTypes.shape({
    contactUsText: PropTypes.string.isRequired,
    sectionHeading: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          number: PropTypes.number.isRequired,
          heading: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default OurAim;
