/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Sections from '../components/sections';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import '../pages/index.scss';

const DynamicPage = ({ data, pageContext }) => {
  const { contentSections, metaData, localizations } = data.strapiPages;
  const global = data.strapiGlobal;
  return (
    <>
      <Seo seo={metaData} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <Sections sections={contentSections} globalData={global} />
      </Layout>
    </>
  );
};

export default DynamicPage;

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    jumpToTop {
      title
    }
    id
    metaData {
      title
      description
      image {
        url
      }
    }
    navbar {
      links {
        url
        title
      }
    }
    footer {
      contactIsmir
      footerInfo
      headerEngagementModels
      headerHome
      headerTeam
      headerTestimonials
      textHome
      textEngagementModels
      textTeam
      textTestimonials
      facebookUrl
      instagramUrl
      linkedinUrl
      dribbbleUrl
    }
  }

  query DynamicPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiPages(id: { eq: $id }) {
      slug
      shortName
      metaData {
        title
        description
        image {
          url
        }
      }
      contentSections
      localizations {
        id
        locale
      }
    }
  }
`;
