import React from 'react';
import PropTypes from 'prop-types';

import './_sliderCard.scss';

const SliderCard = (props) => (
  <div className="container">
    <p className="slider-card-text">{props.description}</p>
    <div className="slider-card-content">
      <img src={props.image} alt={props.name} />
      <div>
        <h2>{props.name}</h2>
        <p>{props.position}</p>
      </div>
    </div>
  </div>
);

SliderCard.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

export default SliderCard;
