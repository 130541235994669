import React from 'react';
import PropTypes from 'prop-types';

const door = 'https://imagizer.imageshack.com/img924/9677/c6ABuI.png';
const gif = 'https://imagizer.imageshack.com/img921/9428/bIOluu.gif';

const OurEngagementModel = (props) => (
  <div className="our-engagement-model">
    <div className="container-fluid">
      <h1 className="pb-md-4">{props.data.title}</h1>
      <p className="p-header">{props.data.subtitle}</p>
      <div className="wrapper ">
        <img src={gif} alt="matrix" style={{ width: '43%' }} />
        <div className="img-container">
          <img src={door} alt="antcolony ulica softverdziluk" />
        </div>
      </div>
    </div>
  </div>
);

OurEngagementModel.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default OurEngagementModel;
