/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';

import LandingHeader from './Sections/Home/LandingHeader';
import OurAim from './Sections/Home/OurAim';
import Diversity from './Sections/Home/Diversity';
import Workflow from './Sections/Home/Workflow';
import StartupIdea from './Sections/Home/StartupIdea';
import Fun from './Sections/Home/Fun';
import JoinUs from './Sections/Home/JoinUs';
import Team from './Sections/Team';
import EngagementModel from './Sections/EngagementModel';
import Projects from './Sections/Projects';
import Gallery from './Sections/Gallery';
import Contact from './Sections/Contact';
import Jobs from './Sections/Jobs';
import Blog from './Sections/Blog';
import HomeProjects from './Sections/Home/Projects';
import Testimonials from './Sections/Home/Testimonials';

const sectionComponents = {
  'sections.landing-header': LandingHeader,
  'home.our-aim': OurAim,
  'sections.diversity': Diversity,
  'sections.workflow': Workflow,
  'sections.startup-idea': StartupIdea,
  'sections.fun': Fun,
  'sections.join-us': JoinUs,
  'sections.meet-the-team': Team,
  'sections.section': EngagementModel,
  'sections.our-projects': Projects,
  'sections.gallery': Gallery,
  'sections.contact': Contact,
  'sections.jobs': Jobs,
  'sections.blog': Blog,
  'sections.home-projects': HomeProjects,
  'sections.testimonials': Testimonials,
};

const Section = ({ sectionData, globalData }) => {
  const SectionComponent =
    sectionComponents[sectionData.strapi_component || sectionData.__component];
  if (!SectionComponent) {
    return null;
  }

  return <SectionComponent data={sectionData} globalData={globalData} />;
};

const Sections = ({ sections, globalData }) => (
  <div className="flex flex-col">
    {sections.map((section, i) => (
      <Section
        sectionData={section}
        globalData={globalData}
        key={`${section.strapi_component}${(section.id, i)}`}
      />
    ))}
  </div>
);

Section.propTypes = {
  sectionData: PropTypes.shape({
    strapi_component: PropTypes.string.isRequired,
    __component: PropTypes.string,
  }).isRequired,
  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

Sections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  ).isRequired,
  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Sections;
