import React from 'react';
import { Link } from 'gatsby';
import Tilt from 'react-tilt';
import PropTypes from 'prop-types';

const cup = 'https://imagizer.imageshack.com/img923/4848/CwcvSi.png';
const antSitting = 'https://imagizer.imageshack.com/img922/2578/3Ol1Rf.png';
const rocher = 'https://imagizer.imageshack.com/img924/5867/f8toNE.png';

const Diversity = (props) => (
  <div className="diversity">
    <div className="container ">
      <h1>{props.data.title}</h1>
      <p className="p-header">{props.data.subtitle}</p>

      <div className="rocher-wrapper">
        <div className="box bounce">
          <img src={rocher} alt="rocher bounce" />
        </div>
        <div className="image-container">
          <img src={cup} alt="pic" />
        </div>
      </div>
      <div className="one-row">
        <div className="col-12 col-md-6 px-0">
          <Tilt className="Tilt" options={{ max: 35, scale: 1 }}>
            <div className="Tilt-inner">
              <img src={antSitting} alt="pic" />
            </div>
          </Tilt>
        </div>
        {props.data.steps.map((step) => (
          <div key={step.title} className="col-12 col-md-6 diversity-row px-0">
            <div className="d-flex">
              <div className="Oval">3</div>
              <h5>{step.heading}</h5>
            </div>
            <h2>{step.title}</h2>
            <p>{step.description}</p>
            <Link to="/contact">
              <button type="button" className="diversity-button">
                {props.data.buttonText}
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>
);

Diversity.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default Diversity;
