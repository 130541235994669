import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import JumpToTop from '../../../jumpToTop';

const JoinUs = (props) => (
  <>
    <div className="join-us">
      <div className="join-us--picture">
        <div className="col-12 col-md-6 join-us--col">
          <h2>{props.data.title}</h2>
          <p className="pr-md-5">{props.data.subtitle}</p>
          <Link to={props.data.CTA.link}>
            <button type="button" className="join-us--button">
              {props.data.CTA.text}
            </button>
          </Link>
        </div>
      </div>
    </div>
    <JumpToTop data={props.globalData} />
  </>
);

JoinUs.propTypes = {
  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    CTA: PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default JoinUs;
