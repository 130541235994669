import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const pic = 'https://imagizer.imageshack.com/img924/7561/4yrZYS.png';

const JobPosition = (props) => (
  <div className="job-position-header">
    <div className="job-position-header--titles">
      <img src={pic} alt="job position illustration AntColony work chance" />
      <h3>{props.positonTitle}</h3>
    </div>
    <div className="button-container">
      <Link to={`/career/${props.slug}`}>
        <button type="button" className="read-more--button">
          FIND OUT MORE
        </button>
      </Link>
    </div>
  </div>
);

JobPosition.propTypes = {
  positonTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default JobPosition;
