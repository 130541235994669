import React from 'react';
import PropTypes from 'prop-types';

import BlogCard from './BlogCard/index';
import useBlogData from '../../../queries/BlogQueries';
import JumpToTop from '../../jumpToTop';

import './_blog.scss';

const Blog = (props) => {
  const blogs = useBlogData();
  return (
    <div className="blog-container-title">
      <h1>{props.data.title}</h1>
      <div className="blog-container container">
        <div className="row">
          {blogs.map((blog) => (
            <BlogCard
              key={blog.slug}
              slug={blog.slug}
              image={blog.image.url}
              category={blog.category}
              title={blog.title}
              description={blog.desc}
              name={blog.ant?.name}
              date={blog.date}
              ant={blog.ant?.antImage.url}
              content={blog.content}
            />
          ))}
        </div>
      </div>
      <JumpToTop data={props.globalData} />
    </div>
  );
};

Blog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  globalData: PropTypes.shape({
    jumpToTop: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Blog;
