/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './_blogCard.scss';

const readingTime = require('reading-time/lib/reading-time');

const BlogCard = (props) => {
  const stats = readingTime(props.content);
  return (
    <div className="card-container col-12 col-md-6 col-lg-4 col-xs-4">
      <div className="blog-card">
        <Link to={`/blog/${props.slug}`}>
          <div className="card-header">
            <img src={props.image} alt={props.title} />
          </div>
          <div className="card-body">
            <div className="user">
              <img
                src={
                  props.ant ||
                  'https://ant-strapi-storage-bucket.fra1.digitaloceanspaces.com/antcolony-website/f2b744c0a1500ab0cceb5c247472eaa1.png'
                }
                alt={props.name}
              />
              <div className="user-info">
                <h5>{props.name || 'Ant Colony Team'}</h5>
              </div>
            </div>
            <h4 className="card-title">{props.title}</h4>
            <p className="card-description">{props.description}</p>
          </div>
          <div className="tag-container">
            <span className="tag tag-gray">{props.category}</span>
            <p className="card-description">• {stats.text}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  content: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  ant: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default BlogCard;
